import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import { globalMainContainer } from "../../root/global/global";
import { darkColor, lightBlue } from "./../../root/global/colors";

export const NavbarDiv = styled.div`
  padding-block: 15px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff;
`;

export const Container = styled.div`
  ${globalMainContainer};
  margin: 0 auto;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const LeftSide = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
`;

export const HomeDiv = styled.div`
  width: 240px;
  border-right: 1px solid rgba(5, 5, 5, 0.06);

  @media screen and (max-width: 800px) {
    border: none;
    width: auto;
  }
`;

export const HomeLink = styled(Link)`
  text-decoration: none;
  font-weight: 500;
  color: ${darkColor};
  color: rgba(0, 0, 0, 0.88);
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.18px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const NavbarLinks = styled.div`
  display: flex;
  gap: 50px;
  align-items: center;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const NavbarLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 500;
  color: ${darkColor};
  font-size: 15px;

  &.active {
    color: ${lightBlue};
  }
`;

export const RightSide = styled.a`
  display: flex;
  gap: 5px;
  align-items: center;

  text-decoration: none;
  color: ${darkColor};
  font-weight: 500;
  transition: 0.2s linear;

  &:hover {
    color: ${lightBlue};
  }

  span {
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
`;

export const ForImg = styled.div`
  width: 20px;
  height: 20px;

  img {
    width: 100%;
  }
`;
