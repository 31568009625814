import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Progress</BigTitle>
      <BigDesc>Display the current progress of an operation flow.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        If it will take a long time to complete an operation, you can use
        <code className="left">Progress</code> to show the current progress and status.
        <li>
          When an operation will interrupt the current interface, or it needs to
          run in the background for more than 2 seconds.
        </li>
        <li>
          When you need to display the completion percentage of an operation.
        </li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
