import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import TableOne from "./table.component";

function Table() {
  return (
    <Container>
      <Info />
      <Side full>
        <Card type="Simple" text="Basic usage.">
          <TableOne type="simple" />
        </Card>
        <Card
          type="Editable"
          text="Editable table with fixed header and fixed columns"
        >
          <TableOne type="editable" />
        </Card>
      </Side>
    </Container>
  );
}

export default Table;
