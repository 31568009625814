import styled, { css, keyframes } from "styled-components";
import {
  dangerColor,
  darkColor,
  defaultColor,
  infoColor,
  lightBlue,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
  disabledColor,
} from "./../../root/global/colors";

const getWidth = ({ props: { block } }) => {
  return css`
    width: ${block ? "100%" : "auto"};
  `;
};

const getBgColor = ({ props: { disabled, ghost } }, color) => {
  return css`
    background-color: ${ghost
      ? "transparent"
      : disabled
      ? disabledColor
      : color};
  `;
};

const getColor = ({ props: { disabled, ghost, type } }, color) => {
  if (type === "primary" && ghost)
    return css`
      color: ${primaryColor};
    `;
  return css`
    color: ${ghost ? "#fff" : disabled ? secondaryColor : color};
  `;
};

const getType = ({ props }) => {
  switch (props.type) {
    case "primary":
      return css`
        ${(props) => getColor(props, "#fff")};
        ${(props) => getBgColor(props, primaryColor)};
        border-color: ${props.disabled ? defaultColor : primaryColor};

        svg {
          path {
            fill: #fff;
          }
        }
      `;
    case "default":
      return css`
        ${(props) => getBgColor(props, "#fff")};
        ${(props) => getColor(props, darkColor)};
        border-color: ${props.ghost ? "#fff" : defaultColor};
      `;
    case "dashed":
      return css`
        ${(props) => getBgColor(props, "#fff")};
        ${(props) => getColor(props, darkColor)};
        border-color: ${props.ghost ? "#fff" : defaultColor};
        border-style: dashed;
      `;
    case "text":
      return css`
        ${(props) => getBgColor(props, "#fff")};
        ${(props) => getColor(props, darkColor)};
      `;
    case "link":
      return css`
        ${(props) => getBgColor(props, "#fff")};
        ${(props) => getColor(props, lightBlue)};
      `;
    case "success":
      return css`
        background-color: ${successColor};
        color: #fff;
      `;
    case "danger":
      return css`
        background-color: ${dangerColor};
        color: #fff;
      `;
    case "warning":
      return css`
        background-color: ${warningColor};
        color: #fff;
      `;
    case "secondary":
      return css`
        background-color: ${secondaryColor};
        color: #fff;
      `;
    case "info":
      return css`
        background-color: ${infoColor};
        color: #fff;
      `;
    default:
      return css`
        color: #fff;
        background-color: ${primaryColor};
      `;
  }
};

const getBorderRadius = ({ props }) => {
  switch (props.onlyIcon) {
    case true:
      return css`
        border-radius: 50%;
        padding: 4px;
        width: 32px;
      `;
    default:
      return css`
        border-radius: ${props.borderRadius ?? "6px"};
        padding: 4px 15px;
        ${getWidth}
      `;
  }
};

const getSize = ({ props }) => {
  switch (props.size) {
    case "large":
      return css`
        font-size: 18px;
        height: 42px;
        padding: 6px 22px;
      `;
    case "small":
      return css`
        font-size: 10px;
        height: 20px;
        padding: 2px 10px;
      `;
    default:
      return css`
        font-size: 14px;
        height: 32px;
        padding: 4px 15px;
      `;
  }
};

export const ButtonContainer = styled.button`
  ${getWidth}
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid transparent;
  cursor: ${({ props: { disabled } }) =>
    disabled ? "not-allowed" : "pointer"};
  opacity: ${({ props: { opacity } }) => opacity ?? 1};
  ${getType};
  ${getBorderRadius};
  ${getSize};

  ${({ props: { disabled } }) =>
    disabled
      ? null
      : css`
          transition: 0.2s linear;
          &:hover {
            opacity: 0.8;
          }
          &:focus {
            opacity: 0.8;
          }
          &:active {
            opacity: 1;
          }
        `}
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid transparent;
  border-top-color: #fff;
  border-radius: 50%;
  animation: ${rotate} 0.8s linear infinite;
`;
