import styled, { css } from "styled-components";
import {
  darkColor,
  defaultColor,
  primaryColor,
} from "./../../root/global/colors";
import { hexToRGB } from "../../utils/custom-functions";

export const Box = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid
    ${({ checked, props: { disabled } }) =>
      checked && !disabled ? primaryColor : defaultColor};
  border-radius: 3px;
  transition: 0.2s linear;
  position: relative;
`;

export const Checked = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ checked, props: { disabled } }) =>
    checked && disabled
      ? () => hexToRGB(defaultColor, 0.5)
      : checked && !disabled
      ? primaryColor
      : !checked && disabled
      ? () => hexToRGB(defaultColor, 0.5)
      : "transparent"};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.15s linear;

  svg {
    width: 70%;
    height: 90%;
    transition: 0.2s linear;
    transform: scale(${({ checked }) => (checked ? 1 : 0)});
    path {
      fill: ${({ props: { disabled } }) =>
        disabled ? darkColor : "#fff"} !important;
    }
    opacity: ${({ props: { disabled } }) => (disabled ? 0.75 : 1)};
  }
`;

export const CheckboxContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ props: { disabled } }) =>
    disabled ? () => hexToRGB(darkColor, 0.5) : darkColor};
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: ${({ props: { disabled } }) =>
    disabled ? "not-allowed" : "pointer"};

  &:hover {
    ${({ props: { disabled } }) =>
      disabled
        ? null
        : css`
            ${Box} {
              border-color: ${primaryColor};
            }
          `}
  }
`;
