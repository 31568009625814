import React, { useState } from "react";
import { DropdownContainer, DropdownBody, Item } from "./dropdown.style";
import { ReactComponent as ArrowDown } from "../../assets/icons/dropdown/arrow-down.svg";

function DropdownOne({ children, ...rest }) {
  const [clicked, setClicked] = useState(false);
  const [selected, setSelected] = useState(children);

  const handleClick = () => {
    if (rest.type !== "click") return;
    setClicked((prev) => !prev);
  };

  const handleItemClick = (e) => {
    setSelected(e.target.innerText);
  };

  return (
    <DropdownContainer props={rest} onClick={handleClick} clicked={clicked}>
      {rest.selectable ? selected : children}
      {rest.arrow ? <ArrowDown /> : null}
      <DropdownBody props={rest}>
        <Item onClick={handleItemClick}>1st menu item</Item>
        <Item onClick={handleItemClick}>2nd menu item</Item>
        <Item onClick={handleItemClick}>3rd menu item</Item>
      </DropdownBody>
    </DropdownContainer>
  );
}

export default DropdownOne;
