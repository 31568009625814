import React from "react";
import { CardBody, CardDiv, CardFooter, CardHr, CardType } from "./style";

function Card({ children, type, text }) {
  return (
    <CardDiv>
      <CardBody>{children}</CardBody>
      {type ? (
        <CardType>
          <CardHr></CardHr>
          <span>{type}</span>
          <CardHr></CardHr>
        </CardType>
      ) : null}
      <CardFooter>{text}</CardFooter>
    </CardDiv>
  );
}

export default Card;
