import styled, { css } from "styled-components";
import { hexToRGB } from "./../../utils/custom-functions";
import {
  dangerColor,
  infoColor,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
} from "./../../root/global/colors";

export const BadgeContainer = styled.div`
  margin-right: 10px;
  width: 40px;
  height: 40px;
  background-color: ${() => hexToRGB(secondaryColor, 0.5)};
  border-radius: 8px;
  position: relative;
`;

const getType = ({ props: { type } }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "primary":
      return primaryColor;
    case "success":
      return successColor;
    case "danger":
      return dangerColor;
    case "warning":
      return warningColor;
    case "info":
      return infoColor;
    default:
      return primaryColor;
  }
};

export const BadgeCircle = styled.div`
  ${({ props: { self } }) =>
    self
      ? null
      : css`
          position: absolute;
          top: -10px;
          left: calc(100% - 10px);
        `}

  box-sizing: content-box;
  min-width: 10px;
  height: 10px;
  padding: 5px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: ${getType};
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
  transition: 0.2s linear;
`;
