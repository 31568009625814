import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Switch</BigTitle>
      <BigDesc>Switching Selector.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        <li>
          If you need to represent the switching between two states or on-off
          state.
        </li>
        <li>
          The difference between <code>Switch</code> and <code>Checkbox</code>
          is that <code>Switch</code> will trigger a state change directly when
          you toggle it, while <code>Checkbox</code> is generally used for state
          marking, which should work in conjunction with submit operation.
        </li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
