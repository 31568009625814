import React from "react";
import { sidebar } from "./../../utils/sidebar";
import {
  Container,
  CustomLink,
  Section,
  SectionTitle,
} from "./components.style";

function Components(props) {
  return (
    <Container>
      <SectionTitle>Done</SectionTitle>
      <Section>
        {sidebar
          .filter((item) => item.done)
          .map((item) => (
            <CustomLink
              to={"/components" + item.path}
              done="true"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              key={item.id}
            >
              {item.title}
            </CustomLink>
          ))}
      </Section>
      <SectionTitle>Coming Soon</SectionTitle>
      <Section>
        {sidebar
          .filter((item) => !item.done)
          .map((item) => (
            <CustomLink as="div" key={item.id}>
              {item.title}
            </CustomLink>
          ))}
      </Section>
    </Container>
  );
}

export default Components;
