import styled from "styled-components";

export const CardDiv = styled.div`
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 5px;
  width: 100%;
`;

export const CardBody = styled.div`
  padding: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;

  .row {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const CardType = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.88);
    background: #fff;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const CardHr = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(5, 5, 5, 0.06);

  &:first-of-type {
    width: 10%;
  }
`;

export const CardFooter = styled.div`
  padding: 20px;
  font-size: 14px;
  line-height: 1.5;
`;
