import React, { useState } from "react";
import { CheckboxContainer, Box, Checked } from "./checkbox.style";
import { ReactComponent as ChechMark } from "../../assets/icons/checkbox/check-mark.svg";
import Button from "./../Buttons/button.component";

function CheckboxOne({ children, ...rest }) {
  const [checked, setChecked] = useState(rest.defaultChecked ?? false);
  const [disabled, setDisabled] = useState(false);

  const handleClick = () => {
    if (rest.disabled || disabled) return;
    setChecked(!checked);
  };

  return (
    <>
      {rest.controlled ? (
        <>
          <CheckboxContainer
            onClick={handleClick}
            props={{ ...rest, disabled }}
          >
            <Box checked={checked} props={{ ...rest, disabled }}>
              <Checked checked={checked} props={{ ...rest, disabled }}>
                <ChechMark />
              </Checked>
            </Box>
            {checked ? "Checked" : "Unchecked"}-{disabled ? "Disabled" : "Enabled"}
          </CheckboxContainer>
          <div className="row" />
          <div className="row">
            <Button onClick={() => setChecked(!checked)}>
              {checked ? "Uncheck" : "Check"}
            </Button>
            <Button onClick={() => setDisabled(!disabled)}>
              {disabled ? "Enable" : "Disable"}
            </Button>
          </div>
        </>
      ) : (
        <>
          <CheckboxContainer onClick={handleClick} props={rest}>
            <Box checked={checked} props={rest}>
              <Checked checked={checked} props={rest}>
                <ChechMark />
              </Checked>
            </Box>
            {children}
          </CheckboxContainer>
        </>
      )}
    </>
  );
}

export default CheckboxOne;
