import React from "react";
import Card from "../Card";
import Input from "./input.component";
import { Container, Side } from "./../style";
import Info from "./info";

function Inputs(props) {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card type="Basic usage" text="Basic usage example.">
          <Input>Basic usage</Input>
        </Card>
        <Card
          type="Normal primary"
          text="type='primary' size='medium, small' placeholder='normal primary large/default/small'"
        >
          <Input type="primary" size="large">
            Normal primary Large
          </Input>
          <Input type="primary">Normal primary Default</Input>
          <Input type="primary" size="small">
            Normal primary Small
          </Input>
        </Card>
        <Card
          type="Normal danger"
          text="type='danger' size='medium, small' placeholder='normal danger large/default/small'"
        >
          <Input type="danger" size="large">
            Normal danger Large
          </Input>
          <Input type="danger">Normal danger Default</Input>
          <Input type="danger" size="small">
            Normal danger Small
          </Input>
        </Card>
        <Card
          type="Normal secondary"
          text="type='secondary' size='medium, small' placeholder='normal secondary large/default/small'"
        >
          <Input type="secondary" size="large">
            Normal secondary Large
          </Input>
          <Input type="secondary">Normal secondary Default</Input>
          <Input type="secondary" size="small">
            Normal secondary Small
          </Input>
        </Card>
        <Card
          type="Inputs type number"
          text="type='default/primary/success/warning' inputType='number'"
        >
          <Input type="default" inputType="number" size="large">
            Integer number default Large
          </Input>
          <Input type="primary" inputType="number" size="large">
            Integer number primary Large
          </Input>
          <Input type="success" inputType="number">
            Integer number secondary Default
          </Input>
          <Input type="warning" inputType="number" size="small">
            Integer number warning Small
          </Input>
        </Card>
        <Card
          type="Input with icon"
          text="size='medium, small' icon='user' placeholder='input with icon large/default/small'"
        >
          <Input size="large" icon="user">
            input with icon large
          </Input>
          <Input icon="user">input with icon default</Input>
          <Input size="small" icon="user">
            input with icon small
          </Input>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card
          type="Three sizes of Input"
          text="There are three sizes of an Input box: large (38px), default (32px) and small (24px)."
        >
          <Input size="large">Large size</Input>
          <Input>Default size</Input>
          <Input size="small">Small size</Input>
        </Card>
        <Card
          type="Normal success"
          text="type='success' size='medium, small' placeholder='normal success large/default/small'"
        >
          <Input type="success" size="large">
            Normal success Large
          </Input>
          <Input type="success">Normal success Default</Input>
          <Input type="success" size="small">
            Normal success Small
          </Input>
        </Card>
        <Card
          type="Normal warning"
          text="type='warning' size='medium, small' placeholder='normal warning large/default/small'"
        >
          <Input type="warning" size="large">
            Normal warning Large
          </Input>
          <Input type="warning">Normal warning Default</Input>
          <Input type="warning" size="small">
            Normal warning Small
          </Input>
        </Card>
        <Card
          type="Normal disabled"
          text="type='disabled' size='medium, small' placeholder='normal disabled large/default/small'"
        >
          <Input type="disabled" size="large">
            Normal disabled Large
          </Input>
          <Input type="disabled">Normal disabled Default</Input>
          <Input type="disabled" size="small">
            Normal disabled Small
          </Input>
        </Card>
        <Card
          type="Inputs type number, step changeable"
          text="type='default/primary/success/warning' inputType='number' step='0.1'"
        >
          <Input type="default" inputType="number" step="0.1" size="large">
            Floating number default Large
          </Input>
          <Input type="primary" inputType="number" step="0.1" size="large">
            Floating number primary Large
          </Input>
          <Input type="success" inputType="number" step="0.1">
            Floating number secondary Default
          </Input>
          <Input type="warning" inputType="number" step="0.1" size="small">
            Floating number warning Small
          </Input>
        </Card>
      </Side>
    </Container>
  );
}

export default Inputs;
