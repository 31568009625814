import React from "react";
import { FullRow, BigTitle, BigDesc, Title, Desc } from "./../style";

function Info(props) {
  return (
    <FullRow>
      <BigTitle>Dropdown</BigTitle>
      <BigDesc>A dropdown list.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        When there are more than a few options to choose from, you can wrap them
        in a <code>Dropdown</code>. By hovering or clicking on the trigger, a
        dropdown menu will appear, which allows you to choose an option and
        execute the relevant action.
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
