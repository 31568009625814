import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { globalMainContainer } from "../../root/global/global";
import { darkColor, lightBlue } from "./../../root/global/colors";
import { navbarHeight } from "./../../root/global/variables";

export const SidebarDiv = styled.div`
  padding-block: 20px;
  width: 240px;
  position: sticky;
  left: 0;
  top: 58px;
  background-color: #fff;
  border-right: 1px solid #f0f0f0;
  min-height: calc(100vh - ${navbarHeight});
  max-height: calc(100vh - ${navbarHeight});
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 1000px) {
    position: static;
    min-height: auto;
    max-height: auto;
    padding-block: 0;
    border: none;
    width: 100%;
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 0;
  }
  ::-webkit-scrollbar-track {
    box-shadow: none;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 1px 1px 2px #e9e9e9;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #bfbfbf;
    }
  }
`;

export const Container = styled.div`
  ${globalMainContainer};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const SidebarLink = styled(NavLink)`
  text-decoration: none;
  color: ${darkColor};
  font-size: 14px;
  border-radius: 5px 0 0 5px;
  padding-block: 15px;
  padding-inline: 20px;
  padding-left: 0;
  transition: 0.25s linear;

  ${({ done }) =>
    done
      ? css`
          cursor: pointer;
          :hover {
            background-color: #f0f0f0;
            padding-left: 20px;
          }

          &.active {
            color: ${lightBlue};
            background-color: #e6f4ff;
            padding-left: 20px;
          }
        `
      : css`
          cursor: not-allowed;
        `}
`;

export const SectionTitle = styled.div`
  margin-block: 20px 5px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
`;

export const MobileWrapper = styled.div`
  padding-block: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: auto;

  @media screen and (min-width: 1001px) {
    display: none;
  }

  ::-webkit-scrollbar {
    max-height: 5px;
  }
`;

MobileWrapper.Item = styled(NavLink)`
  text-decoration: none;
  padding: 8px 25px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgb(29, 29, 29);
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.25s linear 0s;

  &:hover {
    background-color: rgb(230, 244, 255);
    color: rgb(0, 97, 223);
  }
  &.active {
    background-color: rgb(230, 244, 255);
    color: rgb(0, 97, 223);
  }
`;
