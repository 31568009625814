import React from "react";
import { FullRow, BigTitle, BigDesc, Title, Desc } from "./../style";

function Info(props) {
  return (
    <FullRow>
      <BigTitle>Button</BigTitle>
      <BigDesc>To trigger an operation.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        A button means an operation (or a series of operations). Clicking a
        button will trigger corresponding business logic. In Ant Design we
        provide 5 types of button.
        <li>
          Primary button: indicate the main action, one primary button at most
          in one section.
        </li>
        <li>Default button: indicate a series of actions without priority.</li>
        <li>Dashed button: used for adding action commonly.</li>
        <li>Text button: used for the most secondary action.</li>
        <li>Link button: used for external links.</li>
        And 4 other properties additionally.
        <li>
          <code>danger</code>: used for actions of risk, like deletion or
          authorization.
        </li>
        <li>
          <code>ghost</code>: used in situations with complex background, home
          pages usually.
        </li>
        <li>
          <code>disabled</code>: when actions are not available.
        </li>
        <li>
          <code>loading</code>: add loading spinner in button, avoiding multiple
          submits too.
        </li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
