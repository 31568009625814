import React from "react";
import { HomePageDiv } from "./style";
import { navbar } from "./../../utils/navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../NotFound";

const HomePage = () => {
  return (
    <HomePageDiv>
      <Routes>
        {navbar.map((item) => (
          <Route key={item.id} path={item.path + "/*"} element={item.element} />
        ))}
        <Route path="/" element={<Navigate to="/components" />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </HomePageDiv>
  );
};

export default HomePage;
