// Common Colors
export const darkColor = "#1d1d1d";
export const lightBlue = "#0061DF";

// Components Common Colors
export const primaryColor = "#007bff";
export const successColor = "#28a745";
export const dangerColor = "#dc3545";
export const warningColor = "#ffc107";
export const secondaryColor = "#6c757d";
export const defaultColor = "#d9d9d9";
export const infoColor = "#17a2b8";
export const disabledColor = "rgba(0,0,0,.04)";
