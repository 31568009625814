import styled, { css } from "styled-components";
import { darkColor } from "../../root/global/colors";

const getBodyType = ({ props: { type } }) => {
  switch (type) {
    case "hover":
      return css`
        width: 140px;
      `;
    case "click":
      return css`
        width: 100%;
      `;
    default:
      return css`
        width: auto;
      `;
  }
};

const getBodyPlace = ({ props: { bodyPlace } }) => {
  switch (bodyPlace) {
    case "bottom":
      return css`
        top: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      `;
    case "bottomRight":
      return css`
        top: calc(100% + 5px);
        right: 0;
      `;
    case "top":
      return css`
        bottom: calc(100% + 5px);
        left: 50%;
        transform: translateX(-50%);
      `;
    case "topLeft":
      return css`
        bottom: calc(100% + 5px);
        left: 0;
      `;
    case "topRight":
      return css`
        bottom: calc(100% + 5px);
        right: 0;
      `;
    default:
      return css`
        top: calc(100% + 5px);
        left: 0;
      `;
  }
};

export const DropdownBody = styled.div`
  position: absolute;
  z-index: 1000;
  padding: 4px;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  cursor: default;
  transition: 0.25s linear;
  visibility: hidden;
  opacity: 0;

  ${getBodyType}
  ${getBodyPlace}
`;

export const Item = styled.div`
  padding: 5px 12px;
  font-size: 13px;
  color: ${darkColor};
  border-radius: 4px;
  cursor: pointer;
  transition: 0.25s linear;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const getDropdownType = ({ props: { type }, clicked }) => {
  switch (type) {
    case "hover":
      return css`
        :hover {
          ${DropdownBody} {
            visibility: visible;
            opacity: 1;
          }
          svg {
            transform: rotate(180deg);
          }
        }
      `;
    case "click":
      return clicked
        ? css`
            ${DropdownBody} {
              visibility: visible;
              opacity: 1;
            }
          `
        : css`
            ${DropdownBody} {
              visibility: hidden;
              opacity: 0;
            }
          `;
    default:
      return css``;
  }
};

export const DropdownContainer = styled.div`
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ props: { border } }) =>
    border ? "#d9d9d9" : "transparent"};
  box-shadow: ${({ props: { boxShadow } }) =>
    boxShadow ? "0 2px 0 rgba(0, 0, 0, 0.02)" : "none"};
  font-size: 14px;
  color: ${darkColor};
  width: ${({ props: { width } }) => (width === "full" ? "100%" : "auto")};
  height: 32px;
  padding: 4px 15px;
  border-radius: 6px;
  display: flex;
  justify-content: ${({ props: { width } }) =>
    width === "full" ? "space-between" : "center"};
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
  transition: 0.25s linear;

  svg {
    width: 10px;
    height: 10px;
    transition: 0.25s linear;
    transform: rotate(${({ clicked }) => (clicked ? 180 : 0) + "deg"});
  }

  ${getDropdownType}
`;
