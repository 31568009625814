import React from "react";
import { useState } from "react";
import Button from "./../Buttons/button.component";
import {
  StandardContainer,
  StandardMain,
  Inside,
  StandardDegree,
  CircularContainer,
  CircleContainer,
  CircleProgress,
  CircleProgressValue,
} from "./progress.style";

function ProgressOne({ children, ...rest }) {
  const [standardSize, setStandardSize] = useState(rest.size ?? 0);
  const [circularSize, setCircularSize] = useState(rest.size ?? 0);

  const standardPlus = () => {
    if (standardSize + (rest.step ?? 1) >= 100) setStandardSize(100);
    else setStandardSize(standardSize + (rest.step ?? 1));
    if (circularSize + (rest.step ?? 1) >= 100) setCircularSize(100);
    else setCircularSize(circularSize + (rest.step ?? 1));
  };

  const standardMinus = () => {
    if (standardSize - (rest.step ?? 1) <= 0) setStandardSize(0);
    else setStandardSize(standardSize - (rest.step ?? 1));
    if (circularSize - (rest.step ?? 1) <= 0) setCircularSize(0);
    else setCircularSize(circularSize - (rest.step ?? 1));
  };

  return rest.circular ? (
    <>
      <CircularContainer props={rest}>
        <CircleContainer>
          <CircleProgress size={circularSize} type={rest.type}>
            <CircleProgressValue type={rest.type}>
              {circularSize}%
            </CircleProgressValue>
          </CircleProgress>
        </CircleContainer>
      </CircularContainer>
      {rest.dynamic ? (
        <>
          <div className="row" />
          <div className="row">
            <Button onClick={standardMinus} type="warning">
              -
            </Button>
            <Button onClick={standardPlus} type="success">
              +
            </Button>
          </div>
        </>
      ) : null}
    </>
  ) : (
    <>
      <StandardContainer props={rest}>
        <StandardMain>
          <Inside type={rest.type} size={standardSize}></Inside>
        </StandardMain>
        <StandardDegree>{standardSize}%</StandardDegree>
      </StandardContainer>
      {rest.dynamic ? (
        <>
          <Button onClick={standardMinus} type="warning">
            -
          </Button>
          <Button onClick={standardPlus} type="success">
            +
          </Button>
        </>
      ) : null}
    </>
  );
}

export default ProgressOne;
