import React from "react";
import { TooltipContainer, TooltipShow, Arrow } from "./tooltip.style";

function TooltipOne({ children, ...rest }) {
  return (
    <TooltipContainer props={rest}>
      {children}
      <TooltipShow props={rest}>
        prompt text <Arrow props={rest} />
      </TooltipShow>
    </TooltipContainer>
  );
}

export default TooltipOne;
