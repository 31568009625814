import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import SwitchOne from "./switch.component";

function Switch() {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card type="Basic" text="The most basic usage.">
          <SwitchOne />
        </Card>
        <Card
          type="Disabled"
          text="Disabled state of Switch. Add 'disabled' property."
        >
          <SwitchOne disabled />
        </Card>
        <Card
          type="Text"
          text="With text. Add textOn='text' and textOff='text' properties."
        >
          <SwitchOne textOn={1} textOff={0} />
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card
          type="Basic"
          text="You can give initial on/off case by adding 'off' property."
        >
          <SwitchOne off />
        </Card>
        <Card
          type="Controlled"
          text="Controlled state of Switch. Add 'controlled' property."
        >
          <SwitchOne controlled />
        </Card>
      </Side>
    </Container>
  );
}

export default Switch;
