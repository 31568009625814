import styled, { css } from "styled-components";
import { primaryColor, secondaryColor } from "./../../root/global/colors";
import { hexToRGB } from "../../utils/custom-functions";

const getCircleType = ({ isOn }) => {
  return isOn
    ? css`
        transform: translateX(22px);
      `
    : css`
        transform: translateX(0);
      `;
};

export const Circle = styled.div`
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 9px;
  transition: 0.2s linear;

  ${getCircleType}
`;

export const SwitchContainer = styled.div`
  background-color: ${({ isOn }) =>
    isOn ? primaryColor : () => hexToRGB(secondaryColor, 0.75)};
  min-width: 44px;
  height: 22px;
  padding: 5px;
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: ${({ isOn }) => (isOn ? "flex-start" : "flex-end")};
  user-select: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-radius: 100px;
  position: relative;
  transition: 0.2s linear;

  &:active {
    ${({ disabled }) =>
      disabled
        ? null
        : css`
            ${Circle} {
              width: 22px;
              transform: translateX(${({ isOn }) => (isOn ? "18px" : "0")});
            }
          `}
  }
`;
