import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import AlertOne from "./alert.component";

function Alert() {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card type="Basic" text="The simplest usage for short messages.">
          <AlertOne>Basic Alert</AlertOne>
        </Card>
        <Card
          type="More types"
          text="There are 6 types of Alert. Add type='primary/success/warning/danger/info/secondary' property."
        >
          <AlertOne type="primary" full>
            Primary Alert
          </AlertOne>
          <AlertOne type="success" full>
            Success Alert
          </AlertOne>
          <AlertOne type="warning" full>
            Warning Alert
          </AlertOne>
          <AlertOne type="danger" full>
            Danger Alert
          </AlertOne>
          <AlertOne type="info" full>
            Info Alert
          </AlertOne>
          <AlertOne type="secondary" full>
            Secondary Alert
          </AlertOne>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card
          type="Basic full"
          text="You can get full width alert by adding 'full' property."
        >
          <AlertOne full>Full Width Alert</AlertOne>
        </Card>
        <Card type="Close" text="With close icon. Add 'close' property">
          <AlertOne type="primary" close full>
            Primary Alert
          </AlertOne>
          <AlertOne type="success" close full>
            Success Alert
          </AlertOne>
          <AlertOne type="warning" close full>
            Warning Alert
          </AlertOne>
          <AlertOne type="danger" close full>
            Danger Alert
          </AlertOne>
          <AlertOne type="info" close full>
            Info Alert
          </AlertOne>
          <AlertOne type="secondary" close full>
            Secondary Alert
          </AlertOne>
        </Card>
      </Side>
    </Container>
  );
}

export default Alert;
