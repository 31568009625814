import React from "react";
import Card from "../Card";
import DropdownOne from "./dropdown.component";
import { Container, Side } from "./../style";
import Info from "./info";

function Dropdown(props) {
  return (
    <Container>
      <Info/>
      {/* Left Side */}
      <Side>
        <Card
          type="Basic"
          text="The most basic dropdown menu. type='hover' arrow=true"
        >
          <DropdownOne type="hover" width="auto" arrow>
            Hover me
          </DropdownOne>
        </Card>
        <Card
          type="Basic Selectable"
          text="Supports hover and select dropdown menu by adding 'selectable' property. type='hover' arrow=true"
        >
          <DropdownOne type="hover" width="auto" arrow selectable>
            Hover me and select
          </DropdownOne>
        </Card>
        <Card
          type="Basic Clickable/Selectable"
          text="Supports click and select dropdown menu by adding 'selectable' property. type='click' arrow=true boxShadow=true"
        >
          <DropdownOne
            type="click"
            width="full"
            border
            boxShadow
            arrow
            selectable
          >
            Click me and Select
          </DropdownOne>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card
          type="Placement"
          text="Supports 6 placements by adding 'bodyPlace' property. type='hover' border=true boxShadow=true arrow=false"
        >
          <DropdownOne
            type="hover"
            width="auto"
            border
            boxShadow
            bodyPlace="bottomLeft"
          >
            bottomLeft
          </DropdownOne>
          <DropdownOne
            type="hover"
            width="auto"
            border
            boxShadow
            bodyPlace="bottom"
          >
            bottom
          </DropdownOne>
          <DropdownOne
            type="hover"
            width="auto"
            border
            boxShadow
            bodyPlace="bottomRight"
          >
            bottomRight
          </DropdownOne>
          <DropdownOne
            type="hover"
            width="auto"
            border
            boxShadow
            bodyPlace="topLeft"
          >
            topLeft
          </DropdownOne>
          <DropdownOne
            type="hover"
            width="auto"
            border
            boxShadow
            bodyPlace="top"
          >
            top
          </DropdownOne>
          <DropdownOne
            type="hover"
            width="auto"
            border
            boxShadow
            bodyPlace="topRight"
          >
            topRight
          </DropdownOne>
        </Card>
        <Card
          type="Basic Clickable"
          text="Supports click dropdown menu by adding type='click' property. arrow=true boxShadow=true"
        >
          <DropdownOne type="click" width="full" border boxShadow arrow>
            Click me
          </DropdownOne>
        </Card>
      </Side>
    </Container>
  );
}

export default Dropdown;
