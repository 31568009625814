import Generic from "./../components/Generic/index";
import Buttons from "../components/Buttons";
import Inputs from "../components/Inputs";
import Dropdown from "./../components/Dropdown/index";
import Tooltip from "./../components/Tooltip/index";
import Checkbox from "./../components/Checkbox/index";
import Switch from "./../components/Switch/index";
import Badge from "./../components/Badge/index";
import Table from "../components/Table";
import Alert from "../components/Alert";
import Progress from "../components/Progress";

export const sidebar = [
  {
    id: 1,
    title: "Buttons",
    path: "/buttons",
    element: <Buttons />,
    done: true,
  },
  {
    id: 2,
    title: "Inputs",
    path: "/inputs",
    element: <Inputs />,
    done: true,
  },
  {
    id: 3,
    title: "Dropdown",
    path: "/dropdown",
    element: <Dropdown />,
    done: true,
  },
  {
    id: 4,
    title: "Tooltip",
    path: "/tooltip",
    element: <Tooltip />,
    done: true,
  },
  {
    id: 5,
    title: "Menu",
    path: "/menu",
    element: <Generic />,
  },
  {
    id: 6,
    title: "Steps",
    path: "/steps",
    element: <Generic />,
  },
  {
    id: 7,
    title: "Calendar",
    path: "/calendar",
    element: <Generic />,
  },
  {
    id: 8,
    title: "Maps",
    path: "/maps",
    element: <Generic />,
  },
  {
    id: 9,
    title: "Charts",
    path: "/charts",
    element: <Generic />,
  },
  {
    id: 10,
    title: "Checkbox",
    path: "/checkbox",
    element: <Checkbox />,
    done: true,
  },
  {
    id: 11,
    title: "Forms",
    path: "/forms",
    element: <Generic />,
  },
  {
    id: 12,
    title: "Date picker",
    path: "/date-picker",
    element: <Generic />,
  },
  {
    id: 13,
    title: "Switch",
    path: "/switch",
    element: <Switch />,
    done: true,
  },
  {
    id: 14,
    title: "Transfer",
    path: "/transfer",
    element: <Generic />,
  },
  {
    id: 15,
    title: "Upload",
    path: "/upload",
    element: <Generic />,
  },
  {
    id: 16,
    title: "Avatar",
    path: "/avatar",
    element: <Generic />,
  },
  {
    id: 17,
    title: "Badge",
    path: "/badge",
    element: <Badge />,
    done: true,
  },
  {
    id: 18,
    title: "Card",
    path: "/card",
    element: <Generic />,
  },
  {
    id: 19,
    title: "Carousel",
    path: "/carousel",
    element: <Generic />,
  },
  {
    id: 20,
    title: "Collapse",
    path: "/collapse",
    element: <Generic />,
  },
  {
    id: 21,
    title: "Image",
    path: "/image",
    element: <Generic />,
  },
  {
    id: 22,
    title: "Pagination",
    path: "/pagination",
    element: <Generic />
  },
  {
    id: 23,
    title: "Popover",
    path: "/popover",
    element: <Generic />,
  },
  {
    id: 24,
    title: "Table",
    path: "/table",
    element: <Table />,
    done: true,
  },
  {
    id: 25,
    title: "Tabs",
    path: "/tabs",
    element: <Generic />,
  },
  {
    id: 26,
    title: "Alert",
    path: "/alert",
    element: <Alert />,
    done: true,
  },
  {
    id: 27,
    title: "Drawer",
    path: "/drawer",
    element: <Generic />,
  },
  {
    id: 28,
    title: "Modal",
    path: "/modal",
    element: <Generic />,
  },
  {
    id: 29,
    title: "Notification",
    path: "/notification",
    element: <Generic />,
  },
  {
    id: 30,
    title: "Pop Confirm",
    path: "/pop-confirm",
    element: <Generic />,
  },
  {
    id: 31,
    title: "Progress",
    path: "/progress",
    element: <Progress />,
    done: true,
  },
  {
    id: 32,
    title: "Result",
    path: "/result",
    element: <Generic />,
  },
  {
    id: 33,
    title: "Spinner",
    path: "/spinner",
    element: <Generic />,
  },
];
