import React from "react";
import { useLocation } from "react-router-dom";
import { sidebar } from "./../../utils/sidebar";
import {
  SidebarDiv,
  Container,
  SidebarLink,
  SectionTitle,
  MobileWrapper,
} from "./style";

const Sidebar = () => {
  const location = useLocation();

  return (
    <SidebarDiv>
      <Container>
        <SectionTitle>Done</SectionTitle>
        {sidebar
          .filter((item) => item.done)
          .map((item) => (
            <SidebarLink
              to={"/components" + item.path}
              done="true"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              key={item.id}
            >
              {item.title}
            </SidebarLink>
          ))}
        <SectionTitle>Coming Soon</SectionTitle>
        {sidebar
          .filter((item) => !item.done)
          .map((item) => (
            <SidebarLink as="div" key={item.id}>
              {item.title}
            </SidebarLink>
          ))}
      </Container>
      {location.pathname !== "/components" ? (
        <MobileWrapper>
          {sidebar
            .filter((item) => item.done)
            .map((item) => (
              <MobileWrapper.Item
                to={"/components" + item.path}
                done="true"
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                key={item.id}
              >
                {item.title}
              </MobileWrapper.Item>
            ))}
        </MobileWrapper>
      ) : null}
    </SidebarDiv>
  );
};

export default Sidebar;
