import React from "react";
import { FullRow, BigTitle, BigDesc, Title, Desc } from "./../style";

function Info(props) {
  return (
    <FullRow>
      <BigTitle>Input</BigTitle>
      <BigDesc>
        A basic widget for getting the user input is a text field. Keyboard and
        mouse can be used for providing or changing data.
      </BigDesc>
      <Title>When To Use</Title>
      <Desc>
        <li>A user input in a form field is needed.</li>
        <li>A search input is required.</li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
