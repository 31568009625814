import React, { useState } from "react";
import users from "./data";
import { TableOneDiv } from "./table.style";
import OneRow from "./one-row";

function TableOne({ type }) {
  const [mapUsers] = useState(users);

  return (
    <TableOneDiv type={type}>
      <div className="wrapper">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Website</th>
              {type === "editable" ? <th>Edit</th> : null}
            </tr>
          </thead>
          <tbody>
            {mapUsers.map((item) => (
              <OneRow key={item.id} data={item} type={type} />
            ))}
          </tbody>
        </table>
      </div>
    </TableOneDiv>
  );
}

export default TableOne;
