import styled from "styled-components";
import { globalMainContainer } from "../../root/global/global";
import { navbarHeight } from "../../root/global/variables";

export const OuterContainer = styled.div`
  padding-block: 20px 50px;
  min-height: calc(100vh - ${navbarHeight});
  background-color: #fff;
`;

export const Container = styled.div`
  ${globalMainContainer};
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const ImgContainer = styled.div`
  width: 100%;
  max-width: 450px;
`;
