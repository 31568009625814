import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import ProgressOne from "./progress.component";

function Progress() {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card
          type="Progress bar"
          text="A standard progress bar. You can give initial percentage by adding 'size' property."
        >
          <ProgressOne size={30} />
        </Card>
        <Card
          type="Dynamic"
          text="A dynamic standard progress bar is better. Add 'dynamic' property."
        >
          <ProgressOne dynamic />
          <div className="row" />
          <ProgressOne dynamic step={5} size={50} />
        </Card>
        <Card
          type="Type"
          text="More types of circular progress bar. Add type='primary/success/danger' property. Default: 'primary'."
        >
          <ProgressOne circular size={20} type="primary"></ProgressOne>
          <ProgressOne circular size={40} type="success"></ProgressOne>
          <ProgressOne circular size={60} type="danger"></ProgressOne>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card
          type="Type"
          text="More types of standard progress bar. Add type='primary/success/danger' property. Default: 'primary'."
        >
          <ProgressOne type="primary" size={25} />
          <div className="row" />
          <ProgressOne type="success" size={33} />
          <div className="row" />
          <ProgressOne type="danger" size={53} />
        </Card>
        <Card
          type="Circular progress bar"
          text="A circular progress bar. You can give initial percentage by adding 'size' property."
        >
          <ProgressOne circular size={10}></ProgressOne>
        </Card>
        <Card
          type="Dynamic"
          text="A dynamic circular progress bar is better. Add 'dynamic' property."
        >
          <Side auto>
            <ProgressOne circular size={0} dynamic></ProgressOne>
          </Side>
          <Side auto>
            <ProgressOne circular size={50} step={5} dynamic></ProgressOne>
          </Side>
        </Card>
      </Side>
    </Container>
  );
}

export default Progress;
