import React from "react";
import { InputContainer, InputSelf } from "./input.style";
import { ReactComponent as UserIcon } from "../../assets/icons/inputs/user.svg";

function Input({ children, ...rest }) {
  return (
    <InputContainer>
      <InputSelf
        props={rest}
        type={rest.inputType ?? "text"}
        disabled={rest.type === "disabled"}
        step={rest.step ?? 1}
        placeholder={children}
      />
      {rest.icon ? (
        <label>
          <UserIcon />
        </label>
      ) : null}
    </InputContainer>
  );
}

export default Input;
