import React from "react";
import { navbar } from "./../../utils/navbar";
import {
  NavbarDiv,
  Container,
  NavbarLink,
  LeftSide,
  RightSide,
  ForImg,
  HomeLink,
  HomeDiv,
  NavbarLinks,
} from "./style";
import logo from "../../assets/icons/navbar/logo.png";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";

const Navbar = () => {
  return (
    <NavbarDiv>
      <Container>
        <LeftSide>
          <HomeDiv>
            <HomeLink to="/">
              <Logo /> Ant Design Library
            </HomeLink>
          </HomeDiv>
          <NavbarLinks>
            {navbar.map((item) => (
              <NavbarLink
                to={item.path}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                element={item.element}
                key={item.id}
              >
                {item.title}
              </NavbarLink>
            ))}
          </NavbarLinks>
        </LeftSide>
        <RightSide href="https://topilovdev.uz" target="_blank">
          <ForImg>
            <img src={logo} alt="logo" />
          </ForImg>{" "}
          <span>Topilov Dev</span>
        </RightSide>
      </Container>
    </NavbarDiv>
  );
};

export default Navbar;
