import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import TooltipOne from "./tooltip.component";

function Tooltip() {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card type="Basic" text="The simplest usage.">
          <TooltipOne>Tooltip will show on mouse enter.</TooltipOne>
        </Card>
        <Card
          type="Colorful Tooltip"
          text="We preset a series of colorful Tooltip styles for use in different situations."
        >
          <TooltipOne button type='primary'>Primary</TooltipOne>
          <TooltipOne button type='success'>Success</TooltipOne>
          <TooltipOne button type='danger'>Danger</TooltipOne>
          <TooltipOne button type='warning'>Warning</TooltipOne>
          <TooltipOne button type='secondary'>Secondary</TooltipOne>
          <TooltipOne button type='info'>Info</TooltipOne>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card type="Placement" text="There are 12 placement options available.">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <TooltipOne button place="tl">
              TL
            </TooltipOne>
            <TooltipOne button>Top</TooltipOne>
            <TooltipOne button place="tr">
              TR
            </TooltipOne>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TooltipOne button place="lt">
              LT
            </TooltipOne>
            <TooltipOne button place="rt">
              RT
            </TooltipOne>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TooltipOne button place="left">
              Left
            </TooltipOne>
            <TooltipOne button place="right">
              Right
            </TooltipOne>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <TooltipOne button place="lb">
              LB
            </TooltipOne>
            <TooltipOne button place="rb">
              RB
            </TooltipOne>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <TooltipOne button place="bl">
              BL
            </TooltipOne>
            <TooltipOne button place="bottom">
              Bottom
            </TooltipOne>
            <TooltipOne button place="br">
              BR
            </TooltipOne>
          </div>
        </Card>
      </Side>
    </Container>
  );
}

export default Tooltip;
