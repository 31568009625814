import React from "react";
import Sidebar from "../../components/Sidebar";
import { ComponentsDiv, Container, RightSide, Wrapper } from "./style";
import { sidebar } from "./../../utils/sidebar";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom/dist";
import Components from "./components.component";

const ComponentsPage = () => {
  return (
    <ComponentsDiv>
      <Container>
        <Sidebar />
        <RightSide>
          <Wrapper>
            <Routes>
              {sidebar.map((item) => (
                <Route path={item.path} element={item.element} key={item.id} />
              ))}
              <Route path="/" element={<Components />} />
            </Routes>
          </Wrapper>
        </RightSide>
      </Container>
    </ComponentsDiv>
  );
};

export default ComponentsPage;
