import styled from "styled-components";
import { darkColor } from "./../root/global/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const FullRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BigTitle = styled.h1`
  color: ${darkColor};
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 1px;
`;

export const BigDesc = styled.p`
  color: ${darkColor};
  font-size: 14px;
  line-height: 2;
`;

export const Title = styled.h1`
  margin-top: ${({ top }) => (top ? "20px" : 0)};
  color: ${darkColor};
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 1px;
`;

export const Desc = styled.ul`
  list-style-type: circle;
  color: ${darkColor};
  font-size: 14px;
  line-height: 2;

  li {
    margin-left: 20px;
    padding-left: 4px;
  }

  code {
    margin: 0 1px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    background: rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-radius: 3px;
    &.left {
      margin-left: 5px;
    }
  }
`;

export const Side = styled.div`
  width: ${({ full, auto }) =>
    full ? "100%" : auto ? "auto" : "calc(50% - 10px)"};
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const Icon = styled.span`
  transform: translateY(2px);
`;

export const ColoredBg = styled.div`
  background: rgb(190, 200, 200);
  width: 100%;
  padding: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: flex-start;

  .row {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;
