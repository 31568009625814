import Generic from "./../components/Generic/index";
import ComponentsPage from "./../pages/Components/index";

export const navbar = [
  {
    id: 1,
    title: "Docs",
    path: "/docs",
    element: <Generic />,
  },
  {
    id: 2,
    title: "Components",
    path: "/components",
    element: <ComponentsPage />,
  },
  {
    id: 3,
    title: "Resources",
    path: "/resources",
    element: <Generic />,
  },
];
