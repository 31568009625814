import styled, { css } from "styled-components";
import { hexToRGB } from "../../utils/custom-functions";
import {
  primaryColor,
  dangerColor,
  infoColor,
  successColor,
  warningColor,
  darkColor,
  secondaryColor,
} from "./../../root/global/colors";

const getType = ({ props: { type } }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "primary":
      return css`
        background-color: ${() => hexToRGB(primaryColor, 0.5)};
        border-color: ${primaryColor};
      `;
    case "success":
      return css`
        background-color: ${() => hexToRGB(successColor, 0.5)};
        border-color: ${successColor};
      `;
    case "danger":
      return css`
        background-color: ${() => hexToRGB(dangerColor, 0.5)};
        border-color: ${dangerColor};
      `;
    case "warning":
      return css`
        background-color: ${() => hexToRGB(warningColor, 0.5)};
        border-color: ${warningColor};
      `;
    case "info":
      return css`
        background-color: ${() => hexToRGB(infoColor, 0.5)};
        border-color: ${infoColor};
      `;
    case "secondary":
      return css`
        background-color: ${() => hexToRGB(secondaryColor, 0.5)};
        border-color: ${secondaryColor};
      `;
    default:
      return css`
        background-color: ${() => hexToRGB(primaryColor, 0.5)};
        border-color: ${primaryColor};
      `;
  }
};

export const AlertContainer = styled.div`
  width: ${({ props: { full } }) => (full ? "100%" : "auto")};
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px solid ${primaryColor};
  ${getType}

  font-size: 14px;
  color: ${darkColor};
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;

  svg {
    path {
      fill: ${darkColor};
    }

    cursor: pointer;
  }
`;
