import { useState } from "react";
import { AlertContainer } from "./alert.style";
import { ReactComponent as CloseIcon } from "../../assets/icons/alert/close.svg";

function AlertOne({ children, ...rest }) {
  const [have, setHave] = useState(true);

  return have ? (
    <AlertContainer props={rest}>
      {children}
      {rest.close ? <CloseIcon onClick={() => setHave(false)} /> : null}
    </AlertContainer>
  ) : null;
}

export default AlertOne;
