import React from "react";
import { Container, ImgContainer, OuterContainer } from "./style";
import { ReactComponent as NotFoundImage } from "../../assets/images/404.svg";
import Button from "../../components/Buttons/button.component";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <OuterContainer>
      <Container>
        <ImgContainer>
          <NotFoundImage />
        </ImgContainer>
        <Button onClick={handleClick}>Back Home</Button>
      </Container>
    </OuterContainer>
  );
};

export default NotFound;
