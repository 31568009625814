import React, { useState } from "react";
import { ButtonContainer, Loading } from "./button.style";
import { ReactComponent as Switch } from "../../assets/icons/buttons/switch.svg";
import { ReactComponent as Download } from "../../assets/icons/buttons/download.svg";

function Button({ children, ...rest }) {
  const [loading, setLoading] = useState(false);

  const handleLoading = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleButtonClick = () => {
    handleLoading();
    if (rest.onClick) rest.onClick();
  };

  return (
    <ButtonContainer {...rest} props={rest} onClick={handleButtonClick}>
      {rest.isLoading ? <Loading /> : null}
      {rest.animatedLoading ? <>{loading ? <Loading /> : <Switch />}</> : null}
      {rest.download ? <Download /> : null}
      {children}
    </ButtonContainer>
  );
}

export default Button;
