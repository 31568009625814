import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import BadgeOne from "./badge.component";

function Badge() {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card type="Basic" text="Simplest Usage.">
          <BadgeOne>5</BadgeOne>
          <BadgeOne>0</BadgeOne>
        </Card>
        <Card
          type="Colorful Badge"
          text="We preset a series of colorful Badge styles for use in different situations. You can also set it to a hex color string for custom color. Add type='primary/success/danger/warning/info' property. Default: primary."
        >
          <BadgeOne type="primary">5</BadgeOne>
          <BadgeOne type="success">0</BadgeOne>
          <BadgeOne type="danger">0</BadgeOne>
          <BadgeOne type="warning">0</BadgeOne>
          <BadgeOne type="info">0</BadgeOne>
        </Card>
        <Card
          type="Controlled self"
          text="Add 'controlled' property to provide +/- buttons."
        >
          <BadgeOne self controlled>
            0
          </BadgeOne>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card type="Standalone" text="Add 'self' property to show only badge.">
          <BadgeOne self>11</BadgeOne>
          <BadgeOne self>25</BadgeOne>
          <BadgeOne self>99+</BadgeOne>
        </Card>
        <Card type="Switch show" text="Add 'switch' property.">
          <BadgeOne self switch>
            99+
          </BadgeOne>
        </Card>
        <Card
          type="Controlled"
          text="Add 'controlled' property to provide +/- buttons."
        >
          <BadgeOne controlled>
            0
          </BadgeOne>
        </Card>
      </Side>
    </Container>
  );
}

export default Badge;
