import React, { useState } from "react";
import { SwitchContainer, Circle } from "./switch.style";
import Button from "./../Buttons/button.component";

function SwitchOne(props) {
  const [on, setOn] = useState(!props.off);
  const [disabled, setDisabled] = useState(props.disabled ? true : false);

  const handleSwitch = () => {
    if (disabled) return;
    setOn(!on);
    if (props.onClick) props.onClick();
  };

  return (
    <>
      {props.controlled ? (
        <>
          <SwitchContainer
            {...props}
            props={props}
            isOn={on}
            onClick={handleSwitch}
            disabled={disabled}
          >
            <Circle props={props} isOn={on}></Circle>
          </SwitchContainer>
          <div className="row" />
          <div className="row">
            <Button onClick={() => setDisabled(!disabled)}>
              Toggle disabled
            </Button>
          </div>
        </>
      ) : (
        <SwitchContainer
          {...props}
          props={props}
          isOn={on}
          onClick={handleSwitch}
          disabled={disabled}
        >
          {on ? props.textOn : props.textOff}
          <Circle props={props} isOn={on}></Circle>
        </SwitchContainer>
      )}
    </>
  );
}

export default SwitchOne;
