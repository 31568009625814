import React from "react";
import Navbar from "../components/Navbar";
import { GlobalStyle } from "./global/global";
import Helmet from "./global/helmet";
import { Route } from "react-router-dom";
import { Routes } from "react-router-dom/dist";
import HomePage from "./../pages/Home/index";

function Root() {
  return (
    <>
      <Helmet />
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/*" element={<HomePage />} />
      </Routes>
    </>
  );
}

export default Root;
