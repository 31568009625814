import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Alert</BigTitle>
      <BigDesc>Alert component for feedback.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        <li>When you need to show alert messages to users.</li>
        <li>
          When you need a persistent static container which is closable by user
          actions.
        </li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
