import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Badge</BigTitle>
      <BigDesc>
        Small numerical value or status descriptor for UI elements.
      </BigDesc>
      <Title>When To Use</Title>
      <Desc>
        Badge normally appears in proximity to notifications or user avatars
        with eye-catching appeal, typically displaying unread messages count.
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
