import styled from "styled-components";
import { globalMainContainer } from "./../../root/global/global";

export const ComponentsDiv = styled.div``;

export const Container = styled.div`
  ${globalMainContainer};
  margin-inline: auto;
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const RightSide = styled.div`
  background-color: #fff;
  padding-block: 20px;
  padding-inline-start: 20px;
  width: calc(100% - 240px);

  @media screen and (max-width: 1000px) {
    padding-top: 10px;
    padding-left: 0;
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 2px;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: auto;
`;
