import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Tooltip</BigTitle>
      <BigDesc>A simple text popup tip.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        <li>
          The tip is shown on mouse enter, and is hidden on mouse leave. The
          Tooltip doesn't support complex text or operations.
        </li>
        <li>
          To provide an explanation of a <code>button/text/operation</code>.
          It's often used instead of the html <code>title</code> attribute.
        </li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
