import React from "react";
import Card from "../Card";
import Button from "./button.component";
import { Container, ColoredBg, Icon, Side } from "./../style";
import { ReactComponent as SearchIcon } from "../../assets/icons/buttons/icons8-search-16.svg";
import Info from "./info";

function Buttons(props) {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card
          type="Type"
          text="There are primary, default, dashed, text and link buttons in antd."
        >
          <Button type="primary">Primary Button</Button>
          <Button type="default">Default Button</Button>
          <Button type="dashed">Dashed Button</Button>
          <Button type="text">Text Button</Button>
          <Button type="link">Link Button</Button>
        </Card>
        <Card
          type="Icon"
          text="Button components can contain an Icon. This is done by setting the 'icon' property or placing an Icon component within the Button. If you want specific control over the positioning and placement of the Icon, then that should be done by placing the Icon component within the Button rather than using the 'icon' property."
        >
          <Button type="primary" onlyIcon>
            <Icon>
              <SearchIcon />
            </Icon>
          </Button>
          <Button type="primary" onlyIcon>
            A
          </Button>
          <Button type="primary">
            <Icon>
              <SearchIcon />
            </Icon>
            Search Button
          </Button>
          <Button type="default" onlyIcon>
            <Icon>
              <SearchIcon />
            </Icon>
          </Button>
          <Button type="default">
            <Icon>
              <SearchIcon />
            </Icon>
            Search Button
          </Button>
          <Button type="dashed" onlyIcon>
            <Icon>
              <SearchIcon />
            </Icon>
          </Button>
          <Button type="dashed">
            <Icon>
              <SearchIcon />
            </Icon>
            Search Button
          </Button>
        </Card>
        <Card
          type="Loading"
          text="A loading indicator can be added to a button by setting the 'loading/animatedLoading' property on the Button."
        >
          <div className="row">
            <Button type="primary" opacity="0.7" isLoading>
              Loading
            </Button>
            <Button type="primary" opacity="0.7" isLoading>
              Loading
            </Button>
            <Button type="primary" opacity="0.7" isLoading></Button>
          </div>
          <div className="row">
            <Button type="primary" animatedLoading>
              Click me
            </Button>
            <Button type="primary" animatedLoading>
              Click me
            </Button>
            <Button type="primary" animatedLoading></Button>
          </div>
        </Card>
        <Card
          type="Block Button"
          text="'block' property will make the button fit to its parent width."
        >
          <Button type="primary" block>
            Primary
          </Button>
          <Button type="default" block>
            Default
          </Button>
          <Button type="dashed" block>
            Dashed
          </Button>
          <Button type="text" block>
            Text
          </Button>
          <Button type="link" block>
            Link
          </Button>
        </Card>
        <Card
          type="Ghost Button"
          text="'ghost' property will make button's background transparent, it is commonly used in colored background."
        >
          <ColoredBg>
            <Button type="primary" ghost>
              Primary Button
            </Button>
            <Button type="default" ghost>
              Default Button
            </Button>
            <Button type="dashed" ghost>
              Dashed Button
            </Button>
            <Button type="text" ghost>
              Text Button
            </Button>
            <Button type="link" ghost>
              Link Button
            </Button>
          </ColoredBg>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card
          type="Type(colors)"
          text="There are success, danger, warning, secondary and info buttons in antd."
        >
          <Button type="success">Success Button</Button>
          <Button type="danger">Danger Button</Button>
          <Button type="warning">Warning Button</Button>
          <Button type="secondary">Secondary Button</Button>
          <Button type="info">Info Button</Button>
        </Card>
        <Card
          type="Size"
          text="Ant Design supports a default button size as well as a large and small size. If a large or small button is desired, set the size property to either large or small respectively. Omit the size property for a button with the default size."
        >
          <Button type="primary" size="large">
            Large
          </Button>
          <Button type="default" size="large">
            Large
          </Button>
          <Button type="dashed" size="large">
            Large
          </Button>
          <Button type="link" size="large">
            Large
          </Button>
          <Button type="primary">Default</Button>
          <Button type="default">Default</Button>
          <Button type="dashed">Default</Button>
          <Button type="link">Default</Button>
          <Button type="primary" size="small">
            Small
          </Button>
          <Button type="default" size="small">
            Small
          </Button>
          <Button type="dashed" size="small">
            Small
          </Button>
          <Button type="link" size="small">
            Small
          </Button>
        </Card>
        <Card type="Download" text="Ant Design supports download buttons">
          <Button type="primary" download borderRadius="21px"></Button>
          <Button type="primary" download></Button>
          <Button type="primary" download>
            Download
          </Button>
          <Button type="primary" borderRadius="21px">
            Download
          </Button>
        </Card>
        <Card
          type="Disabled"
          text="To mark a button as disabled, add the disabled property to the Button."
        >
          <div className="row">
            <Button type="primary">Primary</Button>
            <Button type="primary" disabled>
              Primary(disabled)
            </Button>
          </div>
          <div className="row">
            <Button type="default">Default</Button>
            <Button type="default" disabled>
              Default(disabled)
            </Button>
          </div>
          <div className="row">
            <Button type="dashed">Dashed</Button>
            <Button type="dashed" disabled>
              Dashed(disabled)
            </Button>
          </div>
          <div className="row">
            <Button type="text">Text</Button>
            <Button type="text" disabled>
              Text(disabled)
            </Button>
          </div>
          <div className="row">
            <Button type="link">Link</Button>
            <Button type="link" disabled>
              Link(disabled)
            </Button>
          </div>
        </Card>
      </Side>
    </Container>
  );
}

export default Buttons;
