import styled, { css } from "styled-components";
import {
  dangerColor,
  infoColor,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
} from "./../../root/global/colors";

const isButton = ({ props: { button } }) => {
  if (button)
    return css`
      min-width: 70px;
      padding: 4px 15px;
      border-color: #d9d9d9;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      cursor: pointer;
      &:hover {
        color: ${primaryColor};
        border-color: ${primaryColor};
      }
    `;
  else
    return css`
      border-color: transparent;
    `;
};

const getPlace = ({ props: { place } }) => {
  place = place?.toLowerCase();
  switch (place) {
    case "tl":
      return css`
        bottom: calc(100% + 10px);
        left: 0;
      `;
    case "top":
      return css`
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
      `;
    case "tr":
      return css`
        bottom: calc(100% + 10px);
        right: 0;
      `;
    case "lt":
      return css`
        top: 0;
        right: calc(100% + 10px);
      `;
    case "rt":
      return css`
        top: 0;
        left: calc(100% + 10px);
      `;
    case "left":
      return css`
        top: 50%;
        right: calc(100% + 10px);
        transform: translateY(-50%);
      `;
    case "right":
      return css`
        top: 50%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
      `;
    case "lb":
      return css`
        bottom: 0;
        right: calc(100% + 10px);
      `;
    case "rb":
      return css`
        bottom: 0;
        left: calc(100% + 10px);
      `;
    case "bl":
      return css`
        top: calc(100% + 10px);
        left: 0;
      `;
    case "bottom":
      return css`
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
      `;
    case "br":
      return css`
        top: calc(100% + 10px);
        right: 0;
      `;
    default:
      return css`
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%);
      `;
  }
};

const getBgColor = ({ props: { type } }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "primary":
      return css`
        background-color: ${primaryColor};
      `;
    case "success":
      return css`
        background-color: ${successColor};
      `;
    case "danger":
      return css`
        background-color: ${dangerColor};
      `;
    case "warning":
      return css`
        background-color: ${warningColor};
      `;
    case "secondary":
      return css`
        background-color: ${secondaryColor};
      `;
    case "info":
      return css`
        background-color: ${infoColor};
      `;
    default:
      return css`
        background-color: #262626;
      `;
  }
};

export const TooltipShow = styled.div`
  position: absolute;
  z-index: 1000;
  min-width: 32px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: start;
  text-decoration: none;
  word-wrap: break-word;
  border-radius: 6px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  box-sizing: border-box;
  font-size: 14px;
  width: max-content;
  max-width: 250px;
  cursor: default;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s linear;

  ${getBgColor}
  ${getPlace}
`;

const getArrowPlace = ({ props: { place } }) => {
  place = place?.toLowerCase();
  switch (place) {
    case "tl":
      return css`
        bottom: 0;
        left: 20px;
        transform: translate(-50%, 50%) rotate(45deg);
      `;
    case "top":
      return css`
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
      `;
    case "tr":
      return css`
        bottom: 0;
        right: 20px;
        transform: translate(50%, 50%) rotate(45deg);
      `;
    case "lt":
      return css`
        top: 10px;
        right: 0;
        transform: translate(50%, 0) rotate(45deg);
      `;
    case "rt":
      return css`
        top: 10px;
        left: 0;
        transform: translate(-50%, 0) rotate(45deg);
      `;
    case "left":
      return css`
        top: 50%;
        right: 0;
        transform: translate(50%, -50%) rotate(45deg);
      `;
    case "right":
      return css`
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%) rotate(45deg);
      `;
    case "lb":
      return css`
        bottom: 10px;
        right: 0;
        transform: translate(50%, 0) rotate(45deg);
      `;
    case "rb":
      return css`
        bottom: 10px;
        left: 0;
        transform: translate(-50%, 0) rotate(45deg);
      `;
    case "bl":
      return css`
        top: 0;
        left: 20px;
        transform: translate(-50%, -50%) rotate(45deg);
      `;
    case "bottom":
      return css`
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      `;
    case "br":
      return css`
        top: 0;
        right: 20px;
        transform: translate(50%, -50%) rotate(45deg);
      `;
    default:
      return css`
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
      `;
  }
};

export const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 1px;

  ${getArrowPlace}
  ${getBgColor}
`;

export const TooltipContainer = styled.div`
  position: relative;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  height: 32px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.2s linear;

  &:hover {
    overflow: visible;
    ${TooltipShow} {
      visibility: visible;
      opacity: 1;
    }
  }

  ${isButton}
`;
