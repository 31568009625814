import React from "react";
import Card from "../Card";
import { Container, Side } from "./../style";
import Info from "./info";
import CheckboxOne from "./checkbox.component";

function Checkbox() {
  return (
    <Container>
      <Info />
      {/* Left Side */}
      <Side>
        <Card type="Basic" text="Basic usage of checkbox.">
          <CheckboxOne>Checkbox</CheckboxOne>
        </Card>
        <Card
          type="Controlled Checkbox"
          text="Communicated with other components."
        >
          <CheckboxOne defaultChecked controlled/>
        </Card>
      </Side>
      {/* Right Side */}
      <Side>
        <Card type="Disabled" text="Disabled checkbox.">
          <CheckboxOne disabled>
            Checkbox
          </CheckboxOne>
        </Card>
        <Card
          type="Checkbox Group"
          text="Generate a group of checkboxes from an array."
        >
          <div className="row">
            <CheckboxOne defaultChecked>Apple</CheckboxOne>
            <CheckboxOne>Pear</CheckboxOne>
            <CheckboxOne>Orange</CheckboxOne>
          </div>
          <div className="row">
            <CheckboxOne>Apple</CheckboxOne>
            <CheckboxOne defaultChecked>Pear</CheckboxOne>
            <CheckboxOne>Orange</CheckboxOne>
          </div>
          <div className="row">
            <CheckboxOne disabled defaultChecked>
              Apple
            </CheckboxOne>
            <CheckboxOne disabled>
              Pear
            </CheckboxOne>
            <CheckboxOne disabled>
              Orange
            </CheckboxOne>
          </div>
        </Card>
      </Side>
    </Container>
  );
}

export default Checkbox;
