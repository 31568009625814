import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Checkbox</BigTitle>
      <BigDesc>Checkbox component.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        <li>Used for selecting multiple values from several options.</li>
        <li>
          If you use only one checkbox, it is the same as using Switch to toggle
          between two states. The difference is that Switch will trigger the
          state change directly, but Checkbox just marks the state as changed
          and this needs to be submitted.
        </li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
