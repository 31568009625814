import { createGlobalStyle, css } from "styled-components";
import { lightBlue } from "./colors";

// Global Styles | styles parsed to html, body, *, scrollbar
export const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
      font-family: 'Roboto', sans-serif;
      background-color: #fff;
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 10px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 1px 1px 2px #e9e9e9;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #bfbfbf;
        /* border-radius: 20px; */
        border: 2px solid transparent;
    }
    *::selection {
        background: ${lightBlue};
        color: #fff;
        text-shadow: none;
    }
`;

// Global Main Container | For using in components as main wrapper component
export const globalMainContainer = css`
  width: 1400px;

  @media screen and (max-width: 1420px) {
    width: 96%;
  }
`;

// Global Extra Container | For using in components as extra little wrapper component
export const globalExtraContainer = css`
  width: 96%;
`;
