import React from "react";
import { FullRow, BigDesc, BigTitle, Title, Desc } from "./../style";

function Info() {
  return (
    <FullRow>
      <BigTitle>Table</BigTitle>
      <BigDesc>A table displays rows of data.</BigDesc>
      <Title>When To Use</Title>
      <Desc>
        <li>To display a collection of structured data.</li>
        <li>To sort, search, paginate, filter data.</li>
      </Desc>
      <Title top>Examples</Title>
    </FullRow>
  );
}

export default Info;
