import styled, { css } from "styled-components";
import { hexToRGB } from "../../utils/custom-functions";
import {
  dangerColor,
  defaultColor,
  disabledColor,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
} from "./../../root/global/colors";

const getSize = ({ props: { size } }) => {
  switch (size) {
    case "large":
      return css`
        padding-block: 6px;
        font-size: 16px;
      `;
    case "small":
      return css`
        padding-block: 2px;
        font-size: 12px;
      `;
    default:
      return css`
        padding-block: 4px;
        font-size: 14px;
      `;
  }
};

const returnDependedOnType = (color) => {
  return css`
    border-color: ${color ? () => hexToRGB(color, 0.6) : "#d9d9d9"};
    color: ${color ?? "rgba(0, 0, 0, 0.88)"};
    ${color
      ? css`
          ::placeholder {
            color: ${() => hexToRGB(color, 0.6)};
            opacity: 1; /* Firefox */
          }
        `
      : css``}
    :focus {
      box-shadow: ${() => hexToRGB(color ?? defaultColor, 0.75)} 0px 1px 1px 0px,
        ${() => hexToRGB(color ?? defaultColor, 0.75)} 0px 0px 0px 1px;
    }
  `;
};

export const getType = ({ props: { type } }) => {
  switch (type) {
    case "primary":
      return () => returnDependedOnType(primaryColor);
    case "success":
      return () => returnDependedOnType(successColor);
    case "danger":
      return () => returnDependedOnType(dangerColor);
    case "warning":
      return () => returnDependedOnType(warningColor);
    case "secondary":
      return () => returnDependedOnType(secondaryColor);
    case "disabled":
      return () => returnDependedOnType(disabledColor);
    default:
      return () => returnDependedOnType();
  }
};

export const InputContainer = styled.div`
  width: 100%;
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 30px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const InputSelf = styled.input`
  z-index: 2;
  box-sizing: border-box;
  margin: 0;
  line-height: 1.5714285714285714;
  list-style: none;
  display: inline-block;
  width: 100%;
  padding-inline: ${({ props: { icon } }) => (icon ? "35px 11px" : "11px")};
  min-width: 0;
  background-color: ${({ props: { type } }) =>
    type === "disabled" ? disabledColor : "#fff"};
  cursor: ${({ props: { type } }) =>
    type === "disabled" ? "not-allowed" : "text"};
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  outline: none;
  transition: all 0.2s;
  ${getSize}
  ${getType}
`;
