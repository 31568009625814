import React, { useState } from "react";
import { BadgeContainer, BadgeCircle } from "./badge.style";
import SwitchOne from "./../Switch/switch.component";
import Button from "../Buttons/button.component";

function BadgeOne({ children: propsChildren, ...rest }) {
  const [show, setShow] = useState(true);
  const [children, setChildren] = useState(`${propsChildren}`);

  const handleMinus = () => {
    if (isNaN(Number(children))) return;
    setChildren(+children - 1);
  };

  const handlePlus = () => {
    if (isNaN(Number(children))) return;
    setChildren(+children + 1);
  };

  return rest.self ? (
    <>
      {rest.switch ? <SwitchOne onClick={() => setShow(!show)} /> : null}
      {show ? <BadgeCircle props={rest}>{children}</BadgeCircle> : null}
      {rest.controlled ? (
        <>
          <div className="row" />
          <div className="row">
            <Button type="warning" onClick={handleMinus}>
              -
            </Button>
            <Button type="success" onClick={handlePlus}>
              +
            </Button>
          </div>
        </>
      ) : null}
    </>
  ) : (
    <>
      {rest.switch ? <SwitchOne onClick={() => setShow(!show)} /> : null}
      {show ? (
        <BadgeContainer props={rest}>
          <BadgeCircle props={rest}>{children}</BadgeCircle>
        </BadgeContainer>
      ) : null}
      {rest.controlled ? (
        <>
          <div className="row" />
          <div className="row">
            <Button type="warning" onClick={handleMinus}>
              -
            </Button>
            <Button type="success" onClick={handlePlus}>
              +
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
}

export default BadgeOne;
