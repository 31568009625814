import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { disabledColor, darkColor } from "./../../root/global/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: start;
`;

export const SectionTitle = styled.div`
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 10px;

  :first-child {
    margin-top: 0;
  }
`;

export const CustomLink = styled(Link)`
  text-decoration: none;
  padding: 30px 50px;
  width: calc(100% / 4 - 80px / 4);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${disabledColor};
  color: ${darkColor};
  text-align: center;
  border-radius: 6px;
  transition: 0.25s linear;

  ${({ done }) =>
    done
      ? css`
          cursor: pointer;
          :active {
            transform: translateY(2px);
          }
          :hover {
            background-color: rgb(230, 244, 255);
            color: rgb(0, 97, 223);
          }
        `
      : css`
          cursor: not-allowed;
        `}

  @media screen and (max-width: 1000px) {
    padding: 15px 20px;
  }

  @media screen and (max-width: 500px) {
    font-size: 14px;
    width: calc(100% / 2 - 20px / 2);
  }
`;
