import styled from "styled-components";
import { hexToRGB } from "../../utils/custom-functions";
import {
  primaryColor,
  dangerColor,
  successColor,
  darkColor,
} from "./../../root/global/colors";

export const StandardContainer = styled.div`
  width: 100%;
  height: 10px;
  display: flex;
`;

export const StandardMain = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${() => hexToRGB(darkColor, 0.15)};
  border-radius: 5px;
`;

const getInsideType = ({ type }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "primary":
      return primaryColor;
    case "success":
      return successColor;
    case "danger":
      return dangerColor;
    default:
      return primaryColor;
  }
};

export const Inside = styled.div`
  width: ${({ size }) => (size ? `${size}%` : "0%")};
  height: 100%;
  background-color: ${getInsideType};
  border-radius: 5px;
  transition: 0.2s linear;
`;

export const StandardDegree = styled.div`
  padding-left: 10px;
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${darkColor};
`;

export const CircularContainer = styled.div``;

export const CircleContainer = styled.div`
  display: flex;
  width: 100px;
  border-radius: 8px;
  background: #fff;
  row-gap: 30px;
  flex-direction: column;
  align-items: center;
`;

const getCircularType = ({ size, type }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "primary":
      return size
        ? `conic-gradient(${primaryColor} ${
            size * 3.6
          }deg, rgba(29, 29, 29, 0.15) 0deg)`
        : `conic-gradient(${primaryColor} 0deg, rgba(29, 29, 29, 0.15) 0deg)`;
    case "success":
      return size
        ? `conic-gradient(${successColor} ${
            size * 3.6
          }deg, rgba(29, 29, 29, 0.15) 0deg)`
        : `conic-gradient(${successColor} 0deg, rgba(29, 29, 29, 0.15) 0deg)`;
    case "danger":
      return size
        ? `conic-gradient(${dangerColor} ${
            size * 3.6
          }deg, rgba(29, 29, 29, 0.15) 0deg)`
        : `conic-gradient(${dangerColor} 0deg, rgba(29, 29, 29, 0.15) 0deg)`;
    default:
      return size
        ? `conic-gradient(${primaryColor} ${
            size * 3.6
          }deg, rgba(29, 29, 29, 0.15) 0deg)`
        : `conic-gradient(${primaryColor} 0deg, rgba(29, 29, 29, 0.15) 0deg)`;
  }
};

export const CircleProgress = styled.div`
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: ${getCircularType};
  display: flex;
  align-items: center;
  justify-content: center;

  ::before {
    content: "";
    position: absolute;
    height: 88px;
    width: 88px;
    border-radius: 50%;
    background-color: #fff;
  }
`;

const getCircularTypeColor = ({ type }) => {
  type = type?.toLowerCase();
  switch (type) {
    case "primary":
      return primaryColor;
    case "success":
      return successColor;
    case "danger":
      return dangerColor;
    default:
      return primaryColor;
  }
};

export const CircleProgressValue = styled.span`
  position: relative;
  font-size: 20px;
  font-weight: 600;
  color: ${getCircularTypeColor};
`;
