import React from "react";
import { useLocation } from "react-router-dom";

const Generic = () => {
  const location = useLocation();

  return (
    <h2 style={{ padding: "10px" }}>{location.pathname} coming soon</h2>
  );
};

export default Generic;
